<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">채팅 대시보드</h1>
    <!-- 조회 폼 -->
    <div class="box-wrap d-flex">
      <div>
        <div class="form-inp sm ml-2">
          <v-select
            :items="aspCustList"
            item-text="CD_NM"
            item-value="CD"
            outlined
            hide-details
            label="회사구분"
            placeholder="선택하세요"
            v-model="selected"
            @change="selectMonitoringData"
            :readonly="RDOnly"
          >
            <template v-slot:label>
              회사구분
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
          </v-select>
        </div>
      </div>
      <div class="ml-auto align-self-center">
        <span>최근 업데이트: {{ recentUpdateTime }}</span>
        <!-- 새로고침 버튼 -->
        <v-btn dark small
          class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
          @click="selectMonitoringData"
          plain
          >
          <v-icon small class="svg-main-refresh"></v-icon>
        </v-btn>
      </div>
    </div>
    <!-- 화면 -->
    <div class="box-wrap">
      <div class="d-flex chatdash-wrap">
        <div class="d-flex flex-column">
          <div class="chatdash-box">
            <span class="chatdash-box--tit">배정가능건수</span>
            <span class="chatdash-box--cnt"
              ><span class="clr-error">{{
                readyData.READY_COUNSELOR_CNT
              }}</span>
              / <strong>{{ readyData.READY_COUNSELOR_TOTALCNT }}</strong></span
            >
            <div class="d-flex justify-center">
              <v-icon class="chatdash-box--drop-btn" v-on:click="dropDown()"></v-icon>
            </div>
          </div>
          <div
            class="box-wrap"
            :style="{ display: canAgent }"
          >
          <span class="chatdash-box--cnt">상담중인 상담사 : <strong>{{ readyData.COUNSEL_COUNSELOR_CNT }}</strong></span>
          </div>
          <div class="chatdash-box d-flex mt-3">
            <div class="d-flex justify-center flex-column">
              <span class="chatdash-box--tit">총요청건</span>
              <span class="chatdash-box--cnt"
                ><span class="clr-primary">{{
                  statusData.NUMBER_CUSTOMER_REQUEST
                }}</span></span
              >
            </div>
            <div class="d-flex justify-center flex-column">
              <span class="chatdash-box--tit">총인입건</span>
              <span class="chatdash-box--cnt"
                ><span class="clr-error">{{
                  statusData.NUMBER_INCOMING
                }}</span></span
              >
            </div>
          </div>
          <div class="chatdash-box2 mt-3 flex-grow-1">
            <div class="chatdash-inbox">
              <div>
                <v-icon class="icn-chatdash-time"></v-icon>
                <span class="chatdash-inbox--tit"> 고객 평균대기시간 </span>
                <v-btn class="ml-auto" fab dark x-small @click="toggleTooltip('tog01')">
                  <v-icon dark x-small> mdi-help </v-icon>
                </v-btn>
              </div>
              <div>
                <div class="chatdash-inbox--list">
                  <span>상담건_평균대기</span>
                  <span class="chatdash-inbox--time">{{
                    statusData.AVG_AWT_GIVE_UP
                  }}</span>
                </div>
                <div class="chatdash-inbox--list">
                  <span>포기건_평균대기</span>
                  <span class="chatdash-inbox--time">{{
                    statusData.AVG_AWT_RESPONSE
                  }}</span>
                </div>
              </div>
              <!-- 고객 평균대기시간 모달 -->
              <div class="chatdash-modal" v-if="tog01">
                <strong class="chatdash-modal-tit">고객 평균대기시간</strong>
                <dl>
                  <dt>상담건 평균대기</dt>
                  <dd>상담 건의 평균 대기시간</dd>
                  <dt>포기건 평균대기</dt>
                  <dd>포기 건의 평균 대기시간</dd>
                </dl>
                <p class="chatdash-modal-noti"><strong>평균대기시간 :</strong> Average Wait Time</p>
              </div>
            </div>
            <div class="chatdash-inbox">
              <div>
                <v-icon class="icn-chatdash-time"></v-icon>
                <span class="chatdash-inbox--tit">평균 상담처리시간</span>
                <v-btn class="ml-auto" fab dark x-small @click="toggleTooltip('tog02')">
                  <v-icon dark x-small> mdi-help </v-icon>
                </v-btn>
              </div>
              <div>
                <div class="chatdash-inbox--list">
                  <span>평균상담시간</span>
                  <span class="chatdash-inbox--time">{{
                    statusData.AVG_CHATTING
                  }}</span>
                </div>
                <div class="chatdash-inbox--list">
                  <span>평균후처리시간</span>
                  <span class="chatdash-inbox--time">{{
                    statusData.AVG_AFTER_TREATMENT
                  }}</span>
                </div>
                <div class="chatdash-inbox--list">
                  <span>평균처리시간</span>
                  <span class="chatdash-inbox--time">{{
                    statusData.AVG_CHATTING_AFTER_TREATMENT
                  }}</span>
                </div>
              </div>
              <!-- 평균 상담처리시간 모달 -->
              <div class="chatdash-modal" v-if="tog02">
                <strong class="chatdash-modal-tit">평균 상담처리시간</strong>
                <dl>
                  <dt>평균상담시간 <span class="clr-primary">(Average Talk Time)</span></span></dt>
                  <dd>평균 상담시간</dd>
                  <dt>평균후처리시간<span class="clr-primary">(Average Work Time)</span></dt>
                  <dd>평균 후처리(상담 종류 후 상담이력 저장까지) 시간</dd>
                  <dt>평균처리시간<span class="clr-primary">(Average Handle Time)</span></dt>
                  <dd>평균 처리시간(평균상담시간 + 평균후처리시간)</dd>
                </dl>
              </div>
            </div>
            <div class="chatdash-inbox">
              <div>
                <v-icon class="icn-chatdash-done"></v-icon>
                <span class="chatdash-inbox--tit">상담완료현황</span>
                <v-btn class="ml-auto" fab dark x-small @click="toggleTooltip('tog03')">
                  <v-icon dark x-small> mdi-help </v-icon>
                </v-btn>
              </div>
              <div>
                <div class="chatdash-inbox--list">
                  <span>응대율</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.RATE_RESPONSE
                    }}<span class="chatdash-inbox--unit">%</span></span
                  >
                </div>
                <div class="chatdash-inbox--list">
                  <span>총상담건</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.NUMBER_TOT_PROGRESS
                    }}<span class="chatdash-inbox--unit">건</span></span
                  >
                </div>
                <div class="chatdash-inbox--list">
                  <span>상담완료</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.NUMBER_COMPLETE
                    }}<span class="chatdash-inbox--unit">건</span></span
                  >
                </div>
              </div>
              <!-- 상담완료현황 모달 -->
              <div class="chatdash-modal" v-if="tog03">
                <strong class="chatdash-modal-tit">상담완료현황</strong>
                <dl>
                  <dt>응대율</dt>
                  <dd>상담사에게 배정되어 응대한 비율로, 총상담건을 배정건으로 나눈 백분율</dd>
                  <dt>총상담건</dt>
                  <dd>상담과 전달의 합</dd>
                  <dt>상담완료</dt>
                  <dd>상담완료(완결)와 전달의 합</dd>
                </dl>
              </div>
            </div>
            <div class="chatdash-inbox">
              <div>
                <v-icon class="icn-chatdash-done"></v-icon>
                <span class="chatdash-inbox--tit">고객포기</span>
                <v-btn class="ml-auto" fab dark x-small @click="toggleTooltip('tog04')">
                  <v-icon dark x-small> mdi-help </v-icon>
                </v-btn>
              </div>
              <div>
                <div class="chatdash-inbox--list">
                  <span>상담요청중포기</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.NUMBER_CUSTOMER_GIVE_UP_90
                    }}<span class="chatdash-inbox--unit">건</span></span
                  >
                </div>
                <div class="chatdash-inbox--list">
                  <span>상담배정중포기</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.NUMBER_CUSTOMER_GIVE_UP_91
                    }}<span class="chatdash-inbox--unit">건</span></span
                  >
                </div>
                <div class="chatdash-inbox--list">
                  <span>상담대기중포기</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.NUMBER_CUSTOMER_GIVE_UP_92
                    }}<span class="chatdash-inbox--unit">건</span></span
                  >
                </div>
                <div class="chatdash-inbox--list">
                  <span>전달대기중포기</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.NUMBER_CUSTOMER_GIVE_UP_93
                    }}<span class="chatdash-inbox--unit">건</span></span
                  >
                </div>
              </div>
              <!-- 고객포기 모달 -->
              <div class="chatdash-modal" v-if="tog04">
                <strong class="chatdash-modal-tit">고객포기</strong>
                <dl>
                  <dt>상담요청중포기</dt>
                  <dd>문의유형 선택 단계에서 고객이 종료한 건</dd>
                  <dt>상담배정중포기</dt>
                  <dd>문의유형 선택 후 배정받기 전 고객이 종료한 건</dd>
                  <dt>상담대기중포기</dt>
                  <dd>배분은 받았지만 대기중인 상태에서 고객이 종료한 건</dd>
                  <dt>전달대기중포기</dt>
                  <dd>전달 대기중인 상태에서 고객이 종료한 건</dd>
                </dl>
              </div>
            </div>
            <div class="chatdash-inbox">
              <div>
                <v-icon class="icn-chatdash-done"></v-icon>
                <span class="chatdash-inbox--tit">BUSY</span>
                <v-btn class="ml-auto" fab dark x-small @click="toggleTooltip('tog05')">
                  <v-icon dark x-small> mdi-help </v-icon>
                </v-btn>
              </div>
              <div>
                <div class="chatdash-inbox--list">
                  <span>상담요청불가</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.NUMBER_CUSTOMER_GIVE_UP_80
                    }}<span class="chatdash-inbox--unit">건</span></span
                  >
                </div>
                <div class="chatdash-inbox--list">
                  <span>상담배정불가(SystemClosed)</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.NUMBER_CUSTOMER_GIVE_UP_81
                    }}<span class="chatdash-inbox--unit">건</span></span
                  >
                </div>
                <div class="chatdash-inbox--list">
                  <span>상담요청미선택</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.NUMBER_CUSTOMER_GIVE_UP_82
                    }}<span class="chatdash-inbox--unit">건</span></span
                  >
                </div>
                <div class="chatdash-inbox--list">
                  <span>상담사배정불가(TimeOut)</span>
                  <span class="chatdash-inbox--time"
                    >{{ statusData.NUMBER_CUSTOMER_GIVE_UP_83
                    }}<span class="chatdash-inbox--unit">건</span></span
                  >
                </div>
              </div>
              <!-- BUSY 모달 -->
              <div class="chatdash-modal" v-if="tog05">
                <strong class="chatdash-modal-tit">BUSY</strong>
                <dl>
                  <dt>상담불가(BUSY) 4가지 조건</dt>
                  <dd>-업무시간 체크<br>-신규 고객접수 여부체크<br>-고객접수 제한 건 체크<br>-휴일 체크</dd>
                  <dt>상담요청불가</dt>
                  <dd>문의유형 선택 전, BUSY(상담불가) 4가지 조건 중 하나에 걸렸을 경우의 건</dd>
                  <dt>상담요청미선택</dt>
                  <dd>문의유형을 선택하지 않아 시간초과된 건</dd>
                  <dt>상담사배정불가(TimeOut)</dt>
                  <dd>문의유형 선택 후 상담사를 일정 시간안에 배정받지 못해 종료된 건</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1 ml-3">
          <div class="d-flex justify-space-between">
            <div
              class="chatdash-tab"
              :class="{ active: status.accept }"
              @click="toggleDiv('accept')"
            >
              <v-icon class="icn-chatdash-tab-in"></v-icon>
              <span class="chatdash-tab--tit">인입</span>
              <span class="chatdash-tab--cnt">{{ count.accept }}</span>
            </div>
            <div
              class="chatdash-tab"
              :class="{ active: status.acceptinqry }"
              @click="toggleDiv('acceptinqry')"
            >
              <v-icon class="icn-chatdash-tab-wait"></v-icon>
              <span class="chatdash-tab--tit">배정대기</span>
              <span class="chatdash-tab--cnt">{{ count.acceptinqry }}</span>
            </div>
            <div
              class="chatdash-tab"
              :class="{ active: status.ready }"
              @click="toggleDiv('ready')"
            >
              <v-icon class="icn-chatdash-tab-wait2"></v-icon>
              <span class="chatdash-tab--tit">대기</span>
              <span class="chatdash-tab--cnt">{{ count.ready }}</span>
            </div>
            <div
              class="chatdash-tab"
              :class="{ active: status.counsel }"
              @click="toggleDiv('counsel')"
            >
              <v-icon class="icn-chatdash-tab-consult"></v-icon>
              <span class="chatdash-tab--tit">상담</span>
              <span class="chatdash-tab--cnt">{{ count.counsel }}</span>
            </div>
            <div
              class="chatdash-tab"
              :class="{ active: status.after }"
              @click="toggleDiv('after')"
            >
              <v-icon class="icn-chatdash-tab-move"></v-icon>
              <span class="chatdash-tab--tit">후처리</span>
              <span class="chatdash-tab--cnt">{{ count.after }}</span>
            </div>
          </div>
          <div
            class="box-wrap"
            :style="{ display: visibility.accept }"
          >
            <v-data-table
              dense
              :headers="gridDataHeadersAccept"
              :items-per-page="30"
              item-key="index"
              :page.sync="page"
              hide-default-footer
              class="grid-default"
              @page-count="pageCount = $event"
            >
            </v-data-table>
          </div>
          <div
            class="box-wrap"
            :style="{ display: visibility.acceptinqry }"
          >
            <v-data-table
              dense
              :headers="gridDataHeadersAcceptInqry"
              :items="gridData.gridDataTextAcceptInqry"
              :items-per-page="30"
              item-key="index"
              :page.sync="page"
              hide-default-footer
              class="grid-default"
              @page-count="pageCount = $event"
            >
            </v-data-table>
          </div>
          <div
            class="box-wrap"
            :style="{ display: visibility.ready }"
          >
            <v-data-table
              dense
              :headers="gridDataHeadersReady"
              :items-per-page="30"
              item-key="index"
              :page.sync="page"
              hide-default-footer
              class="grid-default"
              @page-count="pageCount = $event"
            >
            </v-data-table>
          </div>
          <div
            class="box-wrap"
            :style="{ display: visibility.counsel }"
          >
            <v-data-table
              dense
              :headers="gridDataHeadersCounsel"
              :items-per-page="30"
              item-key="index"
              :page.sync="page"
              hide-default-footer
              class="grid-default"
              @page-count="pageCount = $event"
            >
            </v-data-table>
          </div>
          <div
            class="box-wrap"
            :style="{ display: visibility.after }"
          >
            <v-data-table
              dense
              :headers="gridDataHeadersAfter"
              :items-per-page="30"
              item-key="index"
              :page.sync="page"
              hide-default-footer
              class="grid-default"
              @page-count="pageCount = $event"
            >
            </v-data-table>
          </div>
          <!-- 시간대별 요청 차트 -->
          <div class="chatdash-chart">
            <vue-highcharts
              :options="colume_options_1"
              ref="columnChart1"
            ></vue-highcharts>
          </div>
          <!-- 시간대별 완료 차트 -->
          <div class="chatdash-chart">
            <vue-highcharts
              :options="colume_options_2"
              ref="columnChart2"
            ></vue-highcharts>
          </div>
          <!-- 문의유형별 상담요청차트 -->
          <div class="chatdash-chart">
            <vue-highcharts
              :options="colume_options_3"
              ref="columnChart3"
            ></vue-highcharts>
          </div>
          <!-- 채널별 상담요청  차트 -->
          <div class="chatdash-chart">
            <vue-highcharts
              :options="colume_options_4"
              ref="columnChart4"
            ></vue-highcharts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";
import VueHighcharts from "@/components/HighChart";

let resultJson;

const basicData1 = {
  chart: {
    type: "column",
    height: 230
  },
  title: {
    text: "시간대별 요청현황",
  },
  /*
  subtitle: {
    text: "시간대별 요청현황",
  },
  */
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "요청건수",
      align: "high",
    },
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    valueSuffix: " 건",
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "top",
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 1,
    backgroundColor: "#FFFFFF",
    shadow: true,
  },
  credits: {
    enabled: false,
  },
  series: [],
};
const basicData2 = {
  chart: {
    type: "column",
    height: 230
  },
  title: {
    text: "시간대별 완료 현황",
  },
  /*
  subtitle: {
    text: "시간대별 완료 현황",
  },
  */
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "완료건수",
      align: "high",
    },
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    valueSuffix: " 건",
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "top",
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 1,
    backgroundColor: "#FFFFFF",
    shadow: true,
  },
  credits: {
    enabled: false,
  },
  series: [],
};
const basicData3 = {
  chart: {
    type: "column",
    height: 230
  },
  title: {
    text: "문의유형별 상담요청 현황",
  },
  /*
  subtitle: {
    text: "문의유형별 상담요청 현황",
  },
  */
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "요청건수",
      align: "high",
    },
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    valueSuffix: " 건",
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "top",
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 1,
    backgroundColor: "#FFFFFF",
    shadow: true,
  },
  credits: {
    enabled: false,
  },
  series: [],
};
const basicData4 = {
  chart: {
    type: "column",
    height: 230
  },
  title: {
    text: "채널별 상담요청 현황",
  },
  /*
  subtitle: {
    text: "채널별 상담요청 현황",
  },
  */
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "요청건수",
      align: "high",
    },
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    valueSuffix: " 건",
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  legend: {
    layout: "vertical",
    align: "right",
    verticalAlign: "top",
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 1,
    backgroundColor: "#FFFFFF",
    shadow: true,
  },
  credits: {
    enabled: false,
  },
  series: [],
};
export default {
  name: "MENU_M010102", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components: {
    VueHighcharts,
  },
  data: () => ({
    colume_options_1: basicData1,
    colume_options_2: basicData2,
    colume_options_3: basicData3,
    colume_options_4: basicData4,
    status: {
      accept: false,
      acceptinqry: false,
      ready: false,
      counsel: false,
      after: false,
    },
    gridDataHeadersAccept: [
      { text: "채널명", value: "ASP_SENDER_KEY_NM", align: "center", sortable: false },
      { text: "회원/비회원", value: "MEMBER_YN", align: "center", sortable: false },
      { text: "인입시각", value: "header01", align: "center", sortable: false },
    ],
    gridDataHeadersAcceptInqry: [
      { text: "채널명", value: "ASP_SENDER_KEY_NM", align: "center", sortable: false },
      { text: "회원/비회원", value: "MEMBER_YN", align: "center", sortable: false, },
      { text: "문의유형", value: "INQRY_TYP_NM", align: "center", sortable: false },
      { text: "요청시각", value: "TALK_DIST_DT", align: "center", sortable: false },
    ],
    gridDataHeadersReady: [
      { text: "채널명", value: "ASP_SENDER_KEY_NM", align: "center", sortable: false },
      { text: "회원/비회원", value: "MEMBER_YN", align: "center", sortable: false, },
      { text: "문의유형", value: "INQRY_TYP_NM", align: "center", sortable: false },
      { text: "상담사", value: "header01", align: "center", sortable: false },
      { text: "배정시각", value: "header01", align: "center", sortable: false },
      { text: "대기시간", value: "header01", align: "center", sortable: false },
    ],
    gridDataHeadersCounsel: [
      { text: "채널명", value: "ASP_SENDER_KEY_NM", align: "center", sortable: false },
      { text: "회원/비회원", value: "MEMBER_YN", align: "center", sortable: false, },
      { text: "문의유형", value: "INQRY_TYP_NM", align: "center", sortable: false },
      { text: "상담사", value: "header01", align: "center", sortable: false },
      { text: "상담시작시각", value: "header01", align: "center", sortable: false },
      { text: "상담진행시각", value: "header01", align: "center", sortable: false },
      { text: "고객무응답시간", value: "header01", align: "center", sortable: false },
    ],
    gridDataHeadersAfter: [
      { text: "채널명", value: "header01", align: "center", sortable: false },
      { text: "회원/비회원", value: "header01", align: "center", sortable: false, },
      { text: "문의유형", value: "header01", align: "center", sortable: false },
      { text: "상담사", value: "header01", align: "center", sortable: false },
      { text: "채팅종료시각", value: "header01", align: "center", sortable: false },
      { text: "후처리소요시간", value: "header01", align: "center", sortable: false },
    ],
    gridData: {
      gridDataTextAccept: [],
      gridDataTextAcceptInqry: [],
      gridDataTextReady: [],
      gridDataTextCounsel: [],
      gridDataTextAfter: [],
    },
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    totalVisible: 10,
    selected: "",
    readyData: {
      READY_COUNSELOR_CNT: "",
      READY_COUNSELOR_TOTALCNT: "",
      COUNSEL_COUNSELOR_CNT:"",
    },

    statusData: {
      NUMBER_INCOMING: "",
      NUMBER_CUSTOMER_REQUEST: "",
      AVG_AWT_RESPONSE: "",
      AVG_AWT_GIVE_UP: "",
      AVG_CHATTING: "",
      AVG_AFTER_TREATMENT: "",
      AVG_CHATTING_AFTER_TREATMENT: "",
      RATE_RESPONSE: "",
      NUMBER_TOT_PROGRESS: "",
      NUMBER_COMPLETE: "",
      NUMBER_CUSTOMER_GIVE_UP_90: "",
      NUMBER_CUSTOMER_GIVE_UP_91: "",
      NUMBER_CUSTOMER_GIVE_UP_92: "",
      NUMBER_CUSTOMER_GIVE_UP_93: "",
      NUMBER_CUSTOMER_GIVE_UP_80: "",
      NUMBER_CUSTOMER_GIVE_UP_81: "",
      NUMBER_CUSTOMER_GIVE_UP_82: "",
      NUMBER_CUSTOMER_GIVE_UP_83: "",
    },
    count: {
      accept: "",
      acceptinqry: "",
      ready: "",
      counsel: "",
      after: "",
    },

    canAgent: "none",

    visibility: {
      accept: "",
      acceptinqry: "",
      ready: "",
      counsel: "",
      after: "",
    },

    recentUpdateTime: "",

    channel_code: [],
    CHART_CHANNEL_NM:[],
    CHART_CHANNEL_CD:[],

    ATTR_A:'',
    ATTR_B:'',
    ATTR_C:'',
    tog01 : false,
    tog02 : false,
    tog03 : false,
    tog04 : false,
    tog05 : false,

    ARR_DATA1:[],
    TIT_DATA:[],

    RDOnly:false,
  }),
  methods: {
    getUserATTR(){
      //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/ATTR/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.ATTR",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          this.ATTR_A = response.data.DATA[0].USER_ATTR_A;
          this.ATTR_B = response.data.DATA[0].USER_ATTR_B;
          this.ATTR_C = response.data.DATA[0].USER_ATTR_C;
        })
        .catch((err) => {
          alert(err);
        })
    },

    /*

    reflow1() {
      let columnChart = this.$refs.columnChart1;
      setTimeout(() => {
        columnChart.chart.reflow();
        this.setData1();
      }, 500);
    },

    setData1() {
      let columnChart = this.$refs.columnChart1;

      columnChart.removeSeries();

      columnChart.delegateMethod("showLoading", "Loading...");

      let set_data = [];

      for (let i = 0; i < this.CHART_CHANNEL_CD.length; i++) {
        let inner_set_dat = {};
        let array_data = [];

        inner_set_dat["name"] = this.CHART_CHANNEL_NM[i];
        let CHANNEL = "CHECK_ASP_SENDER_KEY_"+this.CHART_CHANNEL_CD[i];

        //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/counsel/rcept-sttus-by-time/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          [CHANNEL]: this.CHART_CHANNEL_CD[i],
          USER_ATTR_A: this.ATTR_A,
          USER_ATTR_B: this.ATTR_B,
          USER_ATTR_C: this.ATTR_C,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.counsel.rcept-sttus-by-time",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          console.log("response", response);
          for(let n=0;n<response.data.DATA.length;n++){
            basicData1.xAxis.categories[n] = response.data.DATA[n].TIMES;
            array_data[n] = response.data.DATA[n].CNT;
          }
        })
        .catch((err) => {
          alert(err);
        })
        inner_set_dat["data"] = array_data;
        set_data.push(inner_set_dat);
      }

      setTimeout(() => {
        set_data.forEach((element) => columnChart.addSeries(element));
        console.log("columnChart", columnChart);
        columnChart.hideLoading();
      }, 1000);

      ////다시 호출
      //setTimeout(() => {
      //  this.setData1();
      //}, 5000);

      console.log("basicData1", basicData1);
    },

    */

    //그래프를 100% 사이즈로 그리기 위하여 컴포넌트가 활성화 된 직후 다시 그리기를 해준다
    reflow() {
      let barChart = this.$refs.barChart;
      setTimeout(() => {
        barChart.chart.reflow();

        //샘플데이터 업데이트
        this.setData();
      }, 500);

      let columnChart = this.$refs.columnChart1;
      setTimeout(() => {
        columnChart.chart.reflow();
      }, 500);
    },
    setData() {
      let columnChart = this.$refs.columnChart1;

      columnChart.removeSeries();

      columnChart.delegateMethod("showLoading", "Loading...");

      let set_data = [];
      for (let i = 0; i < this.CHART_CHANNEL_CD.length; i++) {
        let inner_set_dat = {};
        let array_data = [];
        let date = [];
        date[i] = this.CHART_CHANNEL_NM[i];
        inner_set_dat["name"] = date[i];
        for (let i = 0; i < 5; i++) {
          array_data.push(Math.floor(Math.random() * 40));
        }
        inner_set_dat["data"] = array_data;
      console.log("inner_set_dat", inner_set_dat);
        set_data.push(inner_set_dat);
      }

      console.log("set_data", set_data);

      setTimeout(() => {
        set_data.forEach((element) => columnChart.addSeries(element));
        console.log("columnChart", columnChart);
        columnChart.hideLoading();
      }, 1000);

      /*
      //다시 호출
      setTimeout(() => {
        this.setData();
      }, 5000);
      */
    },

    test(){
      //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/counsel/rcept-sttus-by-time/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          [CHANNEL]: this.CHART_CHANNEL_CD[i],
          USER_ATTR_A: this.ATTR_A,
          USER_ATTR_B: this.ATTR_B,
          USER_ATTR_C: this.ATTR_C,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.counsel.rcept-sttus-by-time",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          console.log("response", response);
          for(let n=0;n<response.data.DATA.length;n++){
            basicData1.xAxis.categories[n] = response.data.DATA[n].TIMES;
            array_data[n] = response.data.DATA[n].CNT;
          }
        })
        .catch((err) => {
          alert(err);
        })
    },



    reflow2() {
      let columnChart = this.$refs.columnChart2;
      setTimeout(() => {
        columnChart.chart.reflow();
        this.setData2();
      }, 500);
    },

    setData2() {
      let columnChart = this.$refs.columnChart2;

      columnChart.removeSeries();

      columnChart.delegateMethod("showLoading", "Loading...");

      let set_data = [];
      let array_data = [];

      for (let i = 0; i < this.CHART_CHANNEL_CD.length; i++) {
        let CHANNEL = "CHECK_ASP_SENDER_KEY_"+this.CHART_CHANNEL_CD[i];

        //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/counsel/compt-sttus-by-time/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          [CHANNEL]: this.CHART_CHANNEL_CD[i],
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.counsel.compt-sttus-by-time",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          for(let n=0;n<response.data.DATA.length;n++){
            basicData2.xAxis.categories[n] = response.data.DATA[n].TIMES;
            array_data[n] = response.data.DATA[n].NUMBER_COMPLETE;
          }
        })
        .catch((err) => {
          alert(err);
        })

        let inner_set_dat = {};
        let date = this.CHART_CHANNEL_NM; //선택 회사의 채팅 채널
        inner_set_dat["name"] = date[i];
        inner_set_dat["data"] = array_data;
        set_data.push(inner_set_dat);
      }

      setTimeout(() => {
        set_data.forEach((element) => columnChart.addSeries(element));
        columnChart.hideLoading();
      }, 1000);

      /*
      //다시 호출
      setTimeout(() => {
        this.setData1();
      }, 5000);
      */
    },

    reflow3() {
      let columnChart = this.$refs.columnChart3;
      setTimeout(() => {
        columnChart.chart.reflow();
        this.setData3();
      }, 500);
    },

    setData3() {
      let columnChart = this.$refs.columnChart3;

      columnChart.removeSeries();

      columnChart.delegateMethod("showLoading", "Loading...");

      let set_data = [];
      let array_data = [];

      for (let i = 0; i < this.CHART_CHANNEL_CD.length; i++) {

        //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/counsel/cnslt-requst-sttus-by-svc/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          USER_ATTR_A: this.ATTR_A,
          USER_ATTR_B: this.ATTR_B,
          USER_ATTR_C: this.ATTR_C,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.counsel.cnslt-requst-sttus-by-svc",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          for(let n=0;n<response.data.DATA.length;n++){
            basicData3.xAxis.categories[n] = response.data.DATA[n].INQRY_TYPE;
            array_data[n] = response.data.DATA[n].CNT;
          }
        })
        .catch((err) => {
          alert(err);
        })

        let inner_set_dat = {};
        let date = this.CHART_CHANNEL_NM; //선택 회사의 채팅 채널
        inner_set_dat["name"] = date[i];
        inner_set_dat["data"] = array_data;
        set_data.push(inner_set_dat);
      }

      setTimeout(() => {
        set_data.forEach((element) => columnChart.addSeries(element));
        columnChart.hideLoading();
      }, 1000);

      /*
      //다시 호출
      setTimeout(() => {
        this.setData1();
      }, 5000);
      */
    },

    reflow4() {
      let columnChart = this.$refs.columnChart4;
      setTimeout(() => {
        columnChart.chart.reflow();
        this.setData4();
      }, 500);
    },

    setData4() {
      let columnChart = this.$refs.columnChart4;

      columnChart.removeSeries();

      columnChart.delegateMethod("showLoading", "Loading...");

      let set_data = [];
      let array_data = [];

      for (let i = 0; i < this.CHART_CHANNEL_CD.length; i++) {
        let CHANNEL = "CHECK_ASP_SENDER_KEY_"+this.CHART_CHANNEL_CD[i];

        //대시보드 데이터 가져오기
        api.post("api/chat/dashboard/counsel/cnslt-requst-sttus-by-plus-frnd/inqire",   //api url입력
        {
          ASP_NEWCUST_KEY: this.selected,
          USER_ATTR_A: this.ATTR_A,
          USER_ATTR_B: this.ATTR_B,
          USER_ATTR_C: this.ATTR_C,
          ASP_USER_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          ASP_CUST_KEY: this.$store.getters['userStore/GE_USER_ROLE'].company[0].CD,
        },
        {head: {
          "SERVICE" : "chat.dashboard.counsel.cnslt-requst-sttus-by-plus-frnd",
          "METHOD" : "inqire",
          "TYPE" : "BIZ_SERVICE",
        }})
        .then((response) => {
          for(let n=0;n<response.data.DATA.length;n++){
            basicData4.xAxis.categories[n] = response.data.DATA[n].TALK_SENDER_NM;
            array_data[n] = response.data.DATA[n].CNT;
          }
        })
        .catch((err) => {
          alert(err);
        })

        let inner_set_dat = {};
        let date = this.CHART_CHANNEL_NM; //선택 회사의 채팅 채널
        inner_set_dat["name"] = date[i];
        inner_set_dat["data"] = array_data;
        set_data.push(inner_set_dat);
      }

      setTimeout(() => {
        set_data.forEach((element) => columnChart.addSeries(element));
        columnChart.hideLoading();
      }, 1000);

      /*
      //다시 호출
      setTimeout(() => {
        this.setData1();
      }, 5000);
      */
    },

    selectValidation(){
      if (this.selected == "" || this.selected == undefined) {
        this.common_alert("회사를 먼저 선택해주세요.","error");
        return false;
      }
      return true;
    },
    async selectMonitoringData() {
      await this.getUserATTR()
      //validation check
      if(!this.selectValidation()) return;

      let URLData = "api/chat/dashboard/counsel/dashboard-data/inqire";
      let HEADER_SERVICE = "chat.dashboard.counsel.dashboard-data";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";

      let jsonData = {
        ASP_CUST_KEY: this.selected,
        ASP_NEWCUST_KEY: this.selected,
        USER_ATTR_A: "A01",
        USER_ATTR_B: "B01",
        USER_ATTR_C: "C01",
      };

      this.CHART_CHANNEL_CD = [];
      this.CHART_CHANNEL_NM = [];
      this.channel_code = await this.mixin_channel_get(this.selected);
      console.log("1@#2#$3$%"+JSON.stringify(this.channel_code));
      for(let i = 0; i<this.channel_code.length; i++){
        this.CHART_CHANNEL_NM[i] = this.channel_code[0].NM;
        this.CHART_CHANNEL_CD[i] = this.channel_code[0].CD;
      }

      //this.setData1();
      this.setData();
      this.setData2();
      this.setData3();
      this.setData4();

      await api
        .post(URLData, jsonData, {
          head: {
            ASYNC: "true",
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          },
        })
        .then((response) => {
          this.readyData.READY_COUNSELOR_TOTALCNT = response.data.DATA[0].READY_COUNSELOR_TOTALCNT;
          this.readyData.READY_COUNSELOR_CNT = response.data.DATA[0].READY_COUNSELOR_CNT;
          this.readyData.COUNSEL_COUNSELOR_CNT = response.data.DATA[0].COUNSEL_COUNSELOR_CNT;

          this.statusData.NUMBER_CUSTOMER_REQUEST = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_REQUEST;
          this.statusData.NUMBER_INCOMING = response.data.CONTACT_STATUS[0].NUMBER_INCOMING;
          this.statusData.AVG_AWT_GIVE_UP = response.data.CONTACT_STATUS[0].AVG_AWT_GIVE_UP;
          this.statusData.AVG_AWT_RESPONSE = response.data.CONTACT_STATUS[0].AVG_AWT_RESPONSE;
          this.statusData.AVG_CHATTING = response.data.CONTACT_STATUS[0].AVG_CHATTING;
          this.statusData.AVG_AFTER_TREATMENT = response.data.CONTACT_STATUS[0].AVG_AFTER_TREATMENT;
          this.statusData.AVG_CHATTING_AFTER_TREATMENT = response.data.CONTACT_STATUS[0].AVG_CHATTING_AFTER_TREATMENT;
          this.statusData.RATE_RESPONSE = response.data.CONTACT_STATUS[0].RATE_RESPONSE;
          this.statusData.NUMBER_TOT_PROGRESS = response.data.CONTACT_STATUS[0].NUMBER_TOT_PROGRESS;
          this.statusData.NUMBER_COMPLETE = response.data.CONTACT_STATUS[0].NUMBER_COMPLETE;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_90 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_90;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_91 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_91;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_92 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_92;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_93 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_93;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_80 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_80;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_81 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_81;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_82 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_82;
          this.statusData.NUMBER_CUSTOMER_GIVE_UP_83 = response.data.CONTACT_STATUS[0].NUMBER_CUSTOMER_GIVE_UP_83;

          const jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          const contactStatus = JSON.parse(JSON.stringify(response.data.CONTACT_STATUS));

          this.statusData = contactStatus[0];
          this.readyData = jsonObj[0];

          //인입, 배정대기, 상담대기, 상담, 후처리 건수 데이터 세팅
          this.setUserReadyStatus("accept");
          this.setUserReadyStatus("acceptinqry");
          this.setUserReadyStatus("ready");
          this.setUserReadyStatus("counsel");
          this.setUserReadyStatus("after");

          //최근 업데이트 날짜 갱신
          this.recentUpdateTime = new Date().toLocaleString();
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
          console.log(err);
        });
    },


      dropDown(){
        if (this.canAgent == "none"){
          this.canAgent = "block";
        } else {
          this.canAgent = "none";
        }
      },

    async setUserReadyStatus(statusType) {
      let URLData = "api/chat/dashboard/counsel/dashboard-table-data/inqire";
      let HEADER_SERVICE = "chat.dashboard.counsel.dashboard-table-data";
      let HEADER_METHOD = "inqire";
      let HEADER_TYPE = "BIZ_SERVICE";

      let jsonData = {
        ASP_CUST_KEY: this.selected,
        ASP_NEWCUST_KEY: this.selected,
        TYPE: statusType,
      };

      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          },
        })
        .then((response) => {
          resultJson = JSON.parse(JSON.stringify(response.data.DATA));
          this.count[statusType] = resultJson.length;
          for (let key in this.gridData) {
            this.gridData[key] = resultJson;
          }
        })
        .catch((err) => {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
          console.log(err);
        });
    },
    toggleDiv(statusType) {
      //validation check
      if(!this.selectValidation()) return;

      let isVisible = false;
      let currentVisible = "";

      //현재 보여지고 있는 grid가 있는지 체크
      for (let key in this.visibility) {
        if (this.visibility[key] == "block") {
          isVisible = !isVisible;
          currentVisible = key;
        }
      }

      //현재 visibility 값 중에 block인 것이 있음
      if (isVisible) {
        //보여지고 있는 div를 다시 클릭했을 때
        if (statusType == currentVisible) {
          //해당 div를 숨긴다.
          this.visibility[statusType] = "none";
          this.status[statusType] = false;
        } else {
          //보여지고 있는 div가 아닌 다른 div를 클릭했을 때
          this.visibility[currentVisible] = "none";
          this.visibility[statusType] = "block";
          this.status[currentVisible] = false;
          this.status[statusType] = true;
          this.setUserReadyStatus(statusType);
        }
      }
      //현재 visibility 값이 전부 none임
      else {
        this.visibility[statusType] = "block";
        this.status[statusType] = true;
        this.setUserReadyStatus(statusType);
      }
    },
    //toggleTooltip
    toggleTooltip(id) {
      if( this[id] === true) {
        this[id] = false;
        return
      }
      const arr = ['tog01','tog02','tog03','tog04','tog05' ];
      arr.forEach((e) => this[e] = false );
      this[id] = !this[id];
    },
  },
  computed: {
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  mounted(){
    for(let i in this.visibility){
      this.visibility[i] = "none";
    }
    //this.reflow1();
    this.reflow();
    this.reflow2();
    this.reflow3();
    this.reflow4();

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.selected = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
};
</script>

<style>
.highcharts-title{
  font-weight: 700;
}
</style>
